import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { useUserState } from '../context/UserContext';
import { Box } from '@mui/material';
import ActivityIndicator from './ActivityIndicator/ActivityIndicator';
// components
const Layout = React.lazy(() => import('./Layout'));
// const Error = React.lazy(() => import('../pages/error'));
const Login = React.lazy(() => import('../pages/login'));

//frontend
const Front = React.lazy(() => import('../frontend/index'));

export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  const loading = () => <Box minHeight={800} display="flex" justifyContent="center" alignItems="center"><ActivityIndicator /></Box>;
  return (
    <Router>
      <React.Suspense fallback={loading()}>
        <Switch>
          {/* <Route
            path="/app"
            render={() => <Redirect to="/" />}
          /> */}
          <Route
            exact
            path={process.env.REACT_APP_ADMIN_FOLDER}
            render={() => <Redirect to={`${process.env.REACT_APP_ADMIN_FOLDER}/dashboard`}/>}
          />
          <PrivateRoute path={process.env.REACT_APP_ADMIN_FOLDER} component={Layout} />
          <PublicRoute path="/auth/login" component={Login} />
          <Route path="/" render={(props) => <Front {...props} />} />
        </Switch>
      </React.Suspense>
    </Router>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
