import React from "react";
import postApi from '../api/postApi';

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "LOGIN_FAILURE":
      return { ...state, isAuthenticated: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  
  var userlogin = localStorage.getItem("userlogin");
  // console.log('UserProvider', userlogin);
  if(userlogin!==null) {
    userlogin = JSON.parse(userlogin);
    // var timestamp = new Date().getTime();
    // if(userlogin.expires_in < parseInt(timestamp/1000, 0)) userlogin = null;
  }
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!userlogin,
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {

  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);
  if (!!login && !!password) {
    const param = {
      email: login,
      password: password,
    }
    postApi(`/login`, param)
    .then(data => {
      // console.log('d', data.access_token);
      // var ts = new Date().getTime();
      // const data = await JSON.parse(d);
      // console.log('data.access_token', d.data);
      if(data && data.access_token!==undefined){
        localStorage.setItem('userlogin', JSON.stringify(data));
        setError(null)
        setIsLoading(false)
        dispatch({ type: 'LOGIN_SUCCESS' })
        history.push(`${process.env.REACT_APP_ADMIN_FOLDER}/dashboard`)
      }else{
        setError(true);
        setIsLoading(false);
      }

    });

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("userlogin");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/auth/login");
}
