import defaultTheme from "./default";

import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

const overrides = {

  typography: {
    "fontFamily": `"Lato", "Helvetica", "Arial", sans-serif`,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.285rem",
      lineHeight: 1.2
    },
    h6: {
      fontSize: "1.142rem",
      lineHeight: 1.3
    },
    small: {
      fontSize: "1.142rem",
    },
    body1: {
      lineHeight: 1.3
    },
  },
  MuiTimelineItem: {
    missingOppositeContent: {
      "&:before": {
        display: "none"
      }
    }
  },

};

export default {
  default: createTheme(deepmerge(defaultTheme, overrides)),
};
