import axios from 'axios';
import global from '../global';

const postApi = async (url, param) => {
  try {
    const config = { crossdomain: true, 'Content-Type': 'multipart/form-data' };
    var bodyFormData = new FormData();
    Object.entries(param).map((e) => {
      return bodyFormData.append(e[0], e[1])
    })
    const res = await axios.post(`${global.host}${url}`, bodyFormData, config);
    // console.log('res.status', res.data);
    if(res.status===200) return res.data;
    else return null;
  } catch (e) {
    return null;
  }
}

export default postApi;
